import Login from '@/components/pages/authentication/Login';

export const LoginPage = () => {
    return (
        <Login/>
    )
}

// export const getServerSideProps = async () => {
//     return {
//         props: {
//             name: 'jack'
//         }
//     }
// }

export default LoginPage;