import { Box, Typography } from '@mui/material';
import dynamic from 'next/dynamic';

const UserAuthForm = dynamic(() => import('./UserAuthForm'), {
  ssr: false,
});

export default function AuthenticationPage() {
  return (
    <Box
      style={{
        margin: '0 auto',
        marginTop: '100px',
        textAlign: 'center',
        width: '600px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Box>
        <h2>Login</h2>
      </Box>
      <Box>
        <UserAuthForm />
      </Box>
    </Box>
  );
}
